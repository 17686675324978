/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import Cookies from 'js-cookie';
import ActionBtn from "../../components/Buttons/ActionBtn/ActionBtn";
import { AuthContext } from "../../context/auth-context";
import { useHttpRequest } from "../../hooks/httpRequest-hook";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import HttpMessagePrompt from "../../components/HttpMessagePrompt/HttpMessagePrompt";
import { ToastContainer, toast } from 'react-toastify';
import CheckBox from "../../components/CheckBox/CheckBox";
import { useHistory } from "react-router-dom";


// Styles
import styles from "./SchoolRegulations.module.scss"
import { StudentContext } from "../../context/student-context";

const SchoolRegulations = () => {

    // Checkbox state
    const [agreeRegulation, setAgreeRegulation] = useState(false);

    const history = useHistory();

    const [formJwt, setFormJwt] = useState(false);

    const { isLoading, error, sendRequest } = useHttpRequest();

    const { setStudentContext, unsetStudentContext } = useContext(StudentContext);

    const [redirectModal, setRedirectModal] = useState(false);

    const [formIsReady, setFormIsReady] = useState(false);

    const auth = useContext(AuthContext);

    // Retrieving the cookie with the token inside.
    const backJwt = Cookies.get("PPITJWT");


    const submitForm = async () => {
        const data = {
            tinyint_6: "1",
        }

        const url = `${process.env.REACT_APP_API_HOST}/profile/profile`;
        try {
            await sendRequest(url, "POST", JSON.stringify(data), {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "application/json",
                'X-CSRF-Token' : formJwt // A REMETTRE AVEC CSRF
            });
            setRedirectModal(true);
        } catch(err) {
            if (err.message === 'CSRF') {
                toast.error("Votre formulaire a expiré. Votre page va être actualisée.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    history.push("/");
                    history.push("/school-regulations");
                }, 3000)
            } else {
                toast.error("Un problème est survenu sur le serveur, veuillez réessayer ultérieurement", {
                    position: "top-right",
                    autoClose: 4500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    }

    const fetchContextHandler = async () => {
        try {
            const url = `${process.env.REACT_APP_API_HOST}/login/reload-context`;
            const loginData = await sendRequest(url, "GET", null, {
                Authorization: "Bearer " + auth.token,
            });

            if (loginData.status === "blocked") {
                unsetStudentContext();
                auth.logout();
            }

            // Pass data to the login Auth Hook
            auth.login(
                loginData.token,
                loginData.expires,
                loginData.role,
                loginData.type,
                loginData.situation,
                loginData.backToken
            );

            // Pass data to student context Hook
            setStudentContext(
                loginData.status,
                loginData.restricted,
                loginData.campus,
                loginData.accademicPeriod,
                loginData.profile_photo,
                loginData.name,
                loginData.finStatus,
                loginData.accademicLevel,
                loginData.school,
                loginData.attestation,
                loginData.certificate,
                loginData.nextYearLevel,
                loginData.nextYearCampus,
                loginData.rythme,
                loginData.offresRestriction,
                loginData.notificationOfferHome
            );
            history.push("/");
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const getFormJwt = async () => {
            try {
                const url = `${process.env.REACT_APP_API_HOST}/profile/get-form-jwt`;
                const formJwtFromBackEnd = await sendRequest(url, "GET", null, {
                    Authorization: "Bearer " + auth.token,
                });
                setFormJwt(formJwtFromBackEnd);
                setFormIsReady(true);
            } catch (err) {
                console.error(err)
            }
        }
        if (!formJwt) getFormJwt();
    }, [formJwt])


    return (
      <>
        {isLoading && (
          <div className="spinner">
            <LoadingSpinner />
          </div>
        )}
        <div className={styles.mainContainer}>
          <ToastContainer />
          {error && (
            <div className="error_wrapper">
              <HttpMessagePrompt error={error} />
            </div>
          )}
          {!isLoading && !error && redirectModal && (
            <div className={styles.subheader_wrapper}>
              <p className={styles.titleP}>
                Merci ! Vous pouvez désormais accéder à votre espace MyGema en
                cliquant sur le bouton ci-dessous !
              </p>
              <ActionBtn
                id="homeBtn"
                btnType="contained"
                btnStyle={styles.btnStyles}
                activeBtnStyle={styles.btn_active}
                btnText="Retourner à l'accueil"
                textStyle={styles.btn_text}
                onClick={fetchContextHandler}
              />
            </div>
          )}
          {!isLoading && !error && formIsReady && !redirectModal && (
            <>
              <p className={styles.titleP}>
                Avant d'accéder à votre espace MyGema, merci de lire et
                d'accepter le règlement intérieur.
              </p>
              <div className={styles.subMainContainer}>
                <p className={styles.linkP}>
                  Pour lire ce document, veuillez cliquer
                  <a
                    href={`https://ppitv3.groupe-gema.com/document/see-document/148097?formJwt=${backJwt}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    ici
                  </a>
                </p>

                <div className={styles.checboxContainer}>
                  <CheckBox
                    id={"agreedRegulation"}
                    key={"agreedRegulation"}
                    isChecked={agreeRegulation}
                    onChange={() => {
                      setAgreeRegulation(!agreeRegulation);
                    }}
                  />
                  <p className={styles.checkboxP}>
                    En cochant cette case, j'assure avoir pris connaissance du
                    document ci dessus.
                  </p>
                </div>
              </div>
              <ActionBtn
                id="submitBtn"
                btnType="contained"
                btnStyle={
                  agreeRegulation ? styles.btnStyles : styles.btnStylesDisabled
                }
                btnText="Envoyer"
                textStyle={styles.btn_text}
                onClick={submitForm}
              />
            </>
          )}
        </div>
      </>
    );
}

export default SchoolRegulations;